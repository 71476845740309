"use strict";

function shortenBreadcrumbPageDesigner() {
    if (document.querySelector(".experience-region.experience-breadcrumbsList")) {
        const breadcrumbList = document.querySelector(".experience-region.experience-breadcrumbsList");
        const breadcrumbItems = breadcrumbList.querySelectorAll(".experience-component.experience-assets-breadcrumb");
        const breadcrumbLength = breadcrumbItems.length;

        // Check if the breadcrumb length is greater than 2 (excluding the home link)
        if (breadcrumbLength > 2) {
            const windowWidth = window.innerWidth;

            // Check if the window width is less than or equal to 768px (adjust this value as needed)
            if (windowWidth <= 768) {
                // Hide all breadcrumb items except the first 2
                for (let i = 2; i < breadcrumbLength; i++) {
                    breadcrumbItems[i].style.display = "none";
                }

                // Add "..." after the second breadcrumb item
                const ellipsis = document.createElement("div");
                ellipsis.classList.add("experience-component", "experience-assets-breadcrumb", "d-lg-none");

                // Add "..." text within the element
                const ellipsisText = document.createElement("a");
                ellipsisText.classList.add("page-breadcrumbs-link", "text-uppercase", "active");
                ellipsisText.textContent = "...";
                ellipsis.appendChild(ellipsisText);

                ellipsis.style.cursor = "pointer";

                // Insert the "..." element after the second breadcrumb item
                breadcrumbItems[1].insertAdjacentElement("afterend", ellipsis);

                // Add click event listener to the "..." element
                ellipsis.addEventListener("click", function () {
                    // Show the hidden breadcrumb items
                    for (let i = 2; i < breadcrumbLength; i++) {
                        breadcrumbItems[i].style.display = "";
                    }
                    // Remove the "..." element
                    ellipsis.remove();
                });
            } else {
                // Reset the breadcrumb to show all items
                for (let i = 2; i < breadcrumbLength; i++) {
                    breadcrumbItems[i].style.display = "";
                }

                // Remove the "..." element if it exists
                const ellipsis = breadcrumbList.querySelector(".experience-component.experience-assets-breadcrumb");
                if (ellipsis && ellipsis.classList.contains("breadcrumb-item-link")) {
                    ellipsis.remove();
                }
            }
        }
    }
}

// Call the function on window resize
window.addEventListener("resize", function () {
    const windowWidth = window.innerWidth;

    // Check if the window width is less than or equal to 768px (adjust this value as needed)
    if (windowWidth <= 768) {
        shortenBreadcrumbPageDesigner();
    } else {
        const breadcrumbList = document.querySelector(".experience-region.experience-breadcrumbsList");
        const breadcrumbItems = breadcrumbList.querySelectorAll(".experience-component.experience-assets-breadcrumb");

        // Reset the breadcrumb to show all items
        for (let i = 2; i < breadcrumbItems.length; i++) {
            breadcrumbItems[i].style.display = "";
        }

        // Remove the "..." element if it exists
        const ellipsis = breadcrumbList.querySelector(".experience-component.experience-assets-breadcrumb");
        if (ellipsis && ellipsis.classList.contains("breadcrumb-item-link")) {
            ellipsis.remove();
        }
    }
});

// Call the function initially
shortenBreadcrumbPageDesigner();
